import axios from "axios";

export const hostname = "https://api-rentcardip.mileprogramer.rs/";
export const frontendUrl = "https://rentcardip.mileprogramer.rs/"

export const defaultAxiosSettings = () => {
    axios.defaults.withXSRFToken = true;
    axios.defaults.withCredentials = true;
    axios.get(hostname + "sanctum/csrf-cookie/");
}
